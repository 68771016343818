import PerfectScrollbar from 'perfect-scrollbar';
import tippy, { hideAll } from 'tippy.js';

var x13lookbookImageWidth = 0;
var windowWidth = 0;
var listHeight = 0;

var psLeft, psRight;

$(window).resize(x13debounce(function () {
    x13lookbookImageWidth = $('.x13lookbook__image').width();
    windowWidth = $(window).width();
    listHeight = $(window).height() - 150 > 600 ? 600 : $(window).height() - 150;
    $('.x13lookbook__product-list').css('max-height', `${listHeight}px`);
    $('.x13lookbook__left-column').css('max-height', `${listHeight}px`);
    if (typeof psLeft == 'object') {
        psLeft.update();
    }
    if (typeof psRight == 'object') {
        psRight.update();
    };

}, 100))


$(document).ready(function () {
    x13lookbookImageWidth = $('.x13lookbook__image').width();
    windowWidth = $(window).width();
    listHeight = $(window).height() - 150 > 600 ? 600 : $(window).height() - 150;
    if ($('.x13lookbook__product-list').length > 0 && $('.x13lookbook__left-column').length > 0) {
        $('.x13lookbook__product-list').css('max-height', `${listHeight}px`);
        $('.x13lookbook__left-column').css('max-height', `${listHeight}px`);
    }
    x13lookbookInitTippy();
    x13lookbookAddToCartBtn();
    x13lookbookDataAction();
    x13lookbookModalActions();
})

$('.x13lookbook__modal').on('shown.bs.modal', function (e) {
    psLeft = new PerfectScrollbar('.x13lookbook__modal .x13lookbook__left-column', { wheelPropagation: false });
    if ($('.x13lookbook__modal .x13lookbook__product-list').length > 0) {
        psRight = new PerfectScrollbar('.x13lookbook__modal .x13lookbook__product-list', { wheelPropagation: false });
    }
  })

$.fn.x13lookbookScrollTo = function (elem, speed) {
    if ($(this).length > 0) {
        $(this).animate({
            scrollTop: $(this).scrollTop() - $(this).offset().top + $(elem).offset().top
        }, speed == undefined ? 1000 : speed);
        return this;
    }
};

function x13debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function x13lookbookInitTippy() {
    tippy('.x13lookbook__tippy', {
        theme: 'light',
        allowHTML: true,
        interactive: true,
        flipOnUpdate: true,
        hideOnClick: false,
        trigger: 'mouseenter',
        appendTo: () => document.body,
        onShow: ({ reference }) => {
            hideAll({ exclude: reference });
            let $ref = $(reference);
            let $parent = $ref.closest('.x13lookbook__image');

            $parent.addClass('tippy-hovered');
        },
        onHide: ({ reference }) => {
            let $ref = $(reference);
            let $parent = $ref.closest('.x13lookbook__image');

            $parent.removeClass('tippy-hovered');
        },
    })
}

function x13lookbookUpdateProductList(modal, index) {
    modal.find('.x13lookbook__product-list').find('li').removeClass('active');
    modal.find('.x13lookbook__product-list').find(`li[data-index="${index}"]`).addClass('active');
}

function x13lookbookClearModalContent(modal) {
    modal.find('.x13lookbook__product-title').html('');
    modal.find('.x13lookbook__product-details').html('');
    modal.find('.x13lookbook__product-image').html('');
    modal.find('.x13lookbook__add-to-cart').html('');
    modal.find('.x13lookbook__product-description').html('');
}

function x13lookbookFillDataProduct(modal, product, omnibus, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').text(product.name);

    let html = '';
    if (product.reference_to_display) {
        html += `<p class="x13lookbook__product-reference"><strong>${x13lookbookIndex}:</strong> ${product.reference_to_display}</p>`
    }

    if (settings.item_price != '0') {
        if (product.show_price && product.has_discount) {
            html += `<p class="x13lookbook__product-prices"><strong>${x13lookbookPrice}:</strong> ${product.price} <span>${product.regular_price}</span></p>`;

            if (typeof omnibus === 'string' && omnibus !== '') {
                html += omnibus;
            }
        }
        else if (product.show_price) {
            html += `<p class="x13lookbook__product-prices"><strong>${x13lookbookPrice}:</strong> ${product.price}</p>`;
        }
    }

    if (settings.item_qty != '0') {
        if (!(!product.add_to_cart_url || (product.quantity < product.minimal_quantity && !product.allow_oosp))) {
            html += `<p class="x13lookbook__product-quantity"><strong>${x13lookbookInStock}:</strong> ${product.quantity}</p>`;
        }
    }

    if (product.attachments.length > 0) {
        html += `<p class="x13lookbook__product-attachments"><strong>${x13lookbookAttachments}:</strong> ${product.attachments.length}</p>`;
    }

    modal.find('.x13lookbook__product-details').html(`${html}`);
    let flags = '<ul class="product-flags">';
    let keys = Object.keys(product.flags);
    for (let i = 0; i < keys.length; i++) {
        flags += `<li class="product-flag ${product.flags[keys[i]].type}">${product.flags[keys[i]].label}</li>`;
    }
    flags += '</ul>';

    if (settings.item_cover != '0' && product.cover != '') {
        modal.find('.x13lookbook__product-image').html(`${flags} <img src="${product.cover}" alt="${product.name}" />`);
    }

    let description = '';
    if (settings.item_shortdescription != '0') {
        description = `${product.description}`;
    }
    let features = '';
    if (settings.item_features != '0') {
        features += '<table class="table table-striped">';
        for (let feature of product.features) {
            features += `<tr><td>${feature.name}</td><td>${feature.value}</td></tr>`;
        }
        features += '</table>';
    }
    modal.find('.x13lookbook__product-description').html(`${description} ${features} `);


    let cartHtml = `<div class="btn-wrapper">`;
    if (settings.item_add_to_cart_button != '0') {
        let addToCartText = x13lookbookAddToCart;

        if (settings.item_view_button != '0') {
            addToCartText = x13lookbookToCart;
        }

        if (!product.add_to_cart_url || (product.quantity < product.minimal_quantity && !product.allow_oosp)) {
            cartHtml += `<span class="btn btn-primary btn-block disabled" href="${product.add_to_cart_url}" title="${x13lookbookAddToCart}" rel="nofollow">${addToCartText}</span>`;
        } else {
            cartHtml += `<a class="btn btn-primary btn-block" href="${product.add_to_cart_url}" title="${x13lookbookAddToCart}" rel="nofollow">${addToCartText}</a>`;
        }
    }

    if (settings.item_view_button != '0') {
        cartHtml += `<a class="btn btn-secondary btn-block" href="${product.link}" title="${x13lookbookViewProduct}" rel="nofollow">${x13lookbookView}</a>`;
    }
    cartHtml += `</div>`;

    if (product.attachments.length == 1) {
        cartHtml += `<a class="btn btn-danger btn-block" href="${product.attachments[0].link}" title="${x13lookbookDownloadFile}" rel="nofollow"> ${x13lookbookDownloadFile}: ${product.attachments[0].name}</a>`;
    } else if (product.attachments.length > 1) {
        cartHtml += `
            <p>
            <a class="btn btn-danger btn-block" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">${x13lookbookShowFiles}</a>
            </p> <div class="collapse" id="collapseExample"><div class="card card-body x13attachments">`;
        for (let i = 0; i < product.attachments.length; i++) {
            cartHtml += `<a href="${product.attachments[i].link}" title="${x13lookbookDownloadFile}">${product.attachments[i].name}</a><br/>`;
        }
        cartHtml += `</div></div>`;
    }

    modal.find('.x13lookbook__add-to-cart').html(cartHtml);

    x13lookbookUpdateProductList(modal, product.dot_index);
}

function x13lookbookFillDataCustom(modal, text, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').text(text.title);
    modal.find('.x13lookbook__product-details').html(text.description);
    x13lookbookUpdateProductList(modal, text.dot_index);
}

function x13lookbookFillDataCms(modal, text, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    modal.find('.x13lookbook__product-details').html(text.description);

    x13lookbookUpdateProductList(modal, text.dot_index);
}

function x13lookbookFillDataManufacturer(modal, text, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    if (settings.item_cover != '0' && text.cover != '') {
        modal.find('.x13lookbook__product-image').html(`<img src="${text.cover}" alt="${text.name}" />`);
    }
    modal.find('.x13lookbook__product-details').html(text.description);

    x13lookbookUpdateProductList(modal, text.dot_index);
}

function x13lookbookFillDataCategory(modal, text, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    if (settings.item_cover != '0' && text.cover != '') {
        modal.find('.x13lookbook__product-image').html(`<img src="${text.cover}" alt="${text.name}" />`);
    }
    x13lookbookUpdateProductList(modal, text.dot_index);
}

function x13lookbookFillDataImage(modal, text, settings) {
    x13lookbookClearModalContent(modal);
    modal.find('.x13lookbook__product-title').text(text.title);
    modal.find('.x13lookbook__product-details').html(text.description);
    if (settings.item_cover != '0' && text.image != '') {
        modal.find('.x13lookbook__product-image').html(`<img src="${text.image}" alt="${text.title}" />`);
    }
    x13lookbookUpdateProductList(modal, text.dot_index);
}

function x13lookbookDataAction() {
    $('li[data-action], .x13lookbook__dot[data-action]').on('click', function (e) {
        let $this = $(this);
        let $action = $this.data('action');
        let item = $this.data($action) === undefined ? $this.closest('li').data($action) : $this.data($action);
        let omnibus = $this.data('omnibus') === undefined ? $this.closest('li').data('omnibus') : $this.data('omnibus');
        let settings = $this.closest('.x13lookbook').data('settings');
        let modal = $(e.target).closest('.x13lookbook').children('.x13lookbook__modal');

        if ($this.hasClass('x13lookbook__dot')) {
            modal.modal('show');
            modal.find('.x13lookbook__product-list').x13lookbookScrollTo(modal.find(`li[data-index="${item.dot_index}"]`), 0);

        } else {
            $('.x13lookbook__left-column').scrollTop(0);
            if (typeof psLeft == 'object') {
                psLeft.update();
            }
            if (typeof psRight == 'object') {
                psRight.update();
            };
        }

        switch ($action) {
            case 'custom':
                x13lookbookFillDataCustom(modal, item, settings);
                break;
            case 'cms':
                x13lookbookFillDataCms(modal, item, settings);
                break;
            case 'manufacturer':
                x13lookbookFillDataManufacturer(modal, item, settings);
                break;
            case 'category':
                x13lookbookFillDataCategory(modal, item, settings);
                break;
            case 'image':
                x13lookbookFillDataImage(modal, item, settings);
                break;
            case 'product':
                x13lookbookFillDataProduct(modal, item, omnibus, settings);
                break;
            default:
                break;
        };

        if ($('.x13lookbook__product-title').html() != '') {
            $('.x13lookbook__product-title').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-title').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }

        if ($('.x13lookbook__product-image').html() != ''
            || $('.x13lookbook__product-details').html() != '') {
            $('.x13lookbook__product-image').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-image').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }

        if ($('.x13lookbook__product-description').html() != ''
            || $('.x13lookbook__add-to-cart').html() != '') {
            $('.x13lookbook__product-description').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-description').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }
    });
}

function x13lookbookModalActions() {
    $('.x13lookbook__close').on('click', function (e) {
        $('.x13lookbook__modal').modal('hide');
    })

    $('.x13lookbook__modal').on('click', function (e) {
        if ($(e.target).hasClass('x13lookbook__modal') || $(e.target).hasClass('x13lookbook__dialog')) {
            $('.x13lookbook__modal').modal('hide');
        }
    });
}

function x13lookbookAddToCartBtn() {
    $(document).on('click', '.add-to-cart-x13lookbook', function (event) {
        event.preventDefault();
        var $this = $(this);
        var query = $this.attr('href');

        $.post(query, 'json').then(function (resp) {
            if (resp !== undefined) {
                let resp = { hasError: false }
                prestashop.emit('updateCart', {
                    reason: {
                        idProduct: $this.data('id-product'),
                        idProductAttribute: $this.data('id-product-attribute'),
                        idCustomization: 0,
                        linkAction: 'add-to-cart',
                    },
                    resp: resp,
                });
            }
        })
    })
};